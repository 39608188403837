<template>
  <div>
    <div class="base-page login-page">
      <auth-navbar />
      <div class="main-content">
        <slot />
      </div>
      <div class="main-footer">
        <public-footer />
      </div>
    </div>
    <div class="full-page-background">
      <img
        :src="backgroundUrl"
        alt="Toolsquare units in a lab"
      >
    </div>
  </div>
</template>
<script>
import AuthNavbar from "~/components/auth/AuthNavbar";
import PublicFooter from "~/components/general/PublicFooter";
import backgroundUrl from "~/assets/img/background/toolsquare.jpg";

export default {
  name: "AuthLayout",
  components: {
    AuthNavbar,
    PublicFooter,
  },
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>
